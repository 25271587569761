<template>
  <div class="main">
    <NavComponent />
    <main class="content">
      <RouterView />
    </main>
    <FooterComponent />
  </div>
</template>

<script setup>
import NavComponent from "./components/NavComponent.vue";
import FooterComponent from "./components/FooterComponent.vue";

import { onMounted } from "vue";
import axios from "axios";

// Fetching products from the API
function fetchProducts() {
  const apiUrl = process.env.VUE_APP_API_URL; // Base URL from environment variables

  axios
    .get(apiUrl + "/api/products")
    .then((response) => {
      console.log("is this working?", response.data);
    })
    .catch((errorResponse) => {
      // Handle error scenario
      console.error("Error fetching products:", errorResponse);
    });
}

// Fetch products when the component is mounted
onMounted(fetchProducts);
</script>

<style>
/* Reset any default margins and paddings */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Styles for the app container */
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%; /* Ensure the app takes full height */
  display: flex;
  flex-direction: column;
}

/* The main container should take up all available vertical space */
.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure it takes full height of the viewport */
}

/* The content should expand to fill the remaining space */
.content {
  flex: 1; /* This allows the content to grow and fill the space */
  padding: 0rem; /* Optional: Add some padding around your content */
}

/* Footer styling */
footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 1rem;
  flex-shrink: 0; /* Prevent the footer from shrinking */
}

/* Navigation link margin for spacing */
.nav-link {
  margin-left: 10px;
  margin-right: 10px;
}
</style>
