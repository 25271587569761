<template>
  <div class="success-page">
    <div class="success-content">
      <h1>Contact formulier opgestuurd!</h1>
      <p>
        Bedankt voor uw bericht! We nemen zo snel mogelijk contact met u op.
      </p>
      <router-link to="/" class="btn btn-primary">Terug naar Home</router-link>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.success-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f4f4f4;
  text-align: center;
  padding: 20px;
}

.success-content {
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.1);
}

.success-content h1 {
  font-size: 2rem;
  color: #42b983;
  margin-bottom: 20px;
}

.success-content p {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 20px;
}

.btn {
  background-color: #42b983;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1rem;
  display: inline-block;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #369f73;
}
</style>
