<template>
  <div class="quote-page">
    <h1>Offerte aanvragen</h1>
    <div class="quote-details">
      <img :src="product.image" :alt="product.name" class="product-image" />
      <h2>{{ product.name }}</h2>
      <p class="price">Prijs per m²: €{{ product.price.toFixed(2) }}</p>

      <div class="form-group">
        <label for="quantity">Aantal m²</label>
        <input v-model="quantity" type="number" min="1" id="quantity" />
      </div>

      <p class="total-price">
        Totale prijs: €{{ (product.price * quantity).toFixed(2) }}
      </p>

      <div class="contact-form">
        <h3>Contactgegevens</h3>
        <div class="form-group">
          <label for="name">Naam</label>
          <input v-model="contact.name" type="text" id="name" required />
          <span v-if="!contact.name && submitted" class="error"
            >*Naam is verplicht</span
          >
        </div>
        <div class="form-group">
          <label for="email">E-mail</label>
          <input v-model="contact.email" type="email" id="email" required />
          <span v-if="!contact.email && submitted" class="error"
            >*E-mail is verplicht</span
          >
        </div>
        <div class="form-group">
          <label for="phone">Telefoonnummer</label>
          <input v-model="contact.phone" type="tel" id="phone" required />
          <span v-if="!contact.phone && submitted" class="error"
            >*Telefoonnummer is verplicht</span
          >
        </div>
        <div class="form-group">
          <label for="message">Bericht</label>
          <textarea v-model="contact.message" id="message" rows="4"></textarea>
        </div>
        <button @click="submitQuote" class="btn btn-primary">
          Offerte aanvragen
        </button>
      </div>
    </div>

    <!-- Loading Popup -->
    <div v-if="loading" class="loading-popup">
      <div class="loading-content">
        <p>Verzenden... Even geduld aub.</p>
        <div class="loader"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import axios from "axios";

const route = useRoute();
const router = useRouter();

const product = ref({
  id: route.query.id,
  name: route.query.name,
  price: parseFloat(route.query.price),
  image: route.query.image,
});

const quantity = ref(1);
const contact = ref({
  name: "",
  email: "",
  phone: "",
  message: "",
});

const submitted = ref(false);
const loading = ref(false); // Loading state

function submitQuote() {
  submitted.value = true;

  // Validate that all required fields are filled
  if (
    !contact.value.name ||
    !contact.value.email ||
    !contact.value.phone ||
    quantity.value < 1
  ) {
    return; // Stop the function if validation fails
  }

  // Prepare the data to be sent
  const requestData = {
    product: product.value,
    quantity: quantity.value,
    contact: contact.value,
    price: parseFloat(route.query.price),
  };

  const apiUrl = process.env.VUE_APP_API_URL;

  // Set loading state to true before sending the request
  loading.value = true;

  // Send the data to the backend using Axios
  axios
    .post(apiUrl + "/api/send-quote", requestData)
    .then((response) => {
      // Handle the success scenario
      console.log("Quote request sent:", response.data);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      router.push({ path: "/offerte-succes" });
    })
    .catch((error) => {
      axios
        .post("https://panjsherbe.onrender.com/api/send-quote", requestData)
        .then((response) => {
          console.log("second try works", response.data);
          console.log("second try works", error);
          router.push({ path: "/offerte-succes" });
        })
        .catch((error) => {
          console.error("Error sending quote request:", error);
          alert("Er is iets misgegaan bij het verzenden van je aanvraag.");
        });
    })
    .finally(() => {
      // Set loading state to false after the request completes
      loading.value = false;
    });
}
</script>

<style lang="scss" scoped>
.quote-page {
  padding: 20px;
  text-align: center;
}

.quote-details {
  max-width: 600px;
  margin: 0 auto;
  text-align: left;
}

.product-image {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 15px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.total-price {
  font-size: 1.2rem;
  margin-top: 10px;
  margin-bottom: 20px;
}

.btn {
  background-color: #42b983;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.btn:hover {
  background-color: #369f73;
}

.error {
  color: red;
  font-size: 0.9rem;
  margin-top: 5px;
  display: block;
}

/* Loading Popup Styles */
.loading-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.loading-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #42b983;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
