<template>
  <footer class="footer">
    <div class="container">
      <div class="footer-top">
        <img
          src="../assets/White logo - no background.png"
          alt="Logo"
          class="footer-logo"
        />
        <hr />

        <address class="footer-address">
          <p>
            Steenweg op Mol 187 <br />
            2360 Oud-Turnhout, Belgie
          </p>
          <hr />
          <p>
            Telefoon: +31 644342494 <br />
            E-mail: info@panjsher.be
          </p>
        </address>
        <hr />
        <hr />
        <hr />
      </div>

      <div class="footer-buttons">
        <a href="#" target="_blank" class="footer-btn"
          ><i class="fab fa-facebook-f"></i> Facebook</a
        >
        <a
          href="https://www.instagram.com/panjshertegels?igsh=MXhjNGUzMno3cWI4MQ=="
          target="_blank"
          class="footer-btn"
          ><i class="fab fa-instagram"></i> Instagram</a
        >
        <a href="#" target="_blank" class="footer-btn"
          ><i class="fab fa-youtube"></i> Youtube</a
        >
        <RouterLink to="/locatie" class="footer-btn">
          <i class="fas fa-map-marker-alt"></i> Locatie
        </RouterLink>
        <RouterLink to="/over-ons" class="footer-btn">
          <i class="fas fa-info-circle"></i> Over Ons
        </RouterLink>
        <RouterLink to="/contact" class="footer-btn"
          ><i class="fas fa-address-book"></i> Contact</RouterLink
        >
        <RouterLink to="/algemene-voorwaarden" class="footer-btn"
          ><i class="fas fa-gavel"></i> A. Voorwaarden</RouterLink
        >
        <RouterLink to="/privacy-policy" class="footer-btn"
          ><i class="fas fa-user-secret"></i> Privacy Policy</RouterLink
        >
      </div>

      <hr />

      <!-- Divider -->
      <div class="footer-divider"></div>

      <div class="footer-bottom">
        <p>
          Made by Emal H. Ajheer &nbsp;&nbsp;&nbsp;
          <a
            href="https://www.linkedin.com/in/emal-h-ajheer-750069120/"
            target="_blank"
            class="footer-linkedin"
          >
            <i class="fab fa-linkedin"></i> LinkedIn
          </a>
        </p>
      </div>
    </div>
  </footer>
</template>

<style scoped>
.footer {
  background-color: #000;
  color: white;
  padding: 2rem 1rem;
  text-align: center;
}

.footer-logo {
  width: 25%; /* Adjust percentage to fit your design */
  max-width: 400px; /* Optional: Set a max width to avoid the logo getting too large */
  margin-bottom: 1rem;
}

.footer-address {
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

.footer-divider {
  width: 80%;
  height: 1px; /* Make the divider thinner */
  background-color: white;
  margin: 1rem auto;
}

.footer-buttons {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
  max-width: 80%;
  margin: 1rem auto;
}

.footer-btn {
  background-color: #555;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  text-decoration: none;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid white;
  justify-content: center;

  min-width: 150px; /* Set a minimum width */
  max-width: 200px; /* Set a maximum width */
  text-align: center;
  box-sizing: border-box;
  flex-grow: 1; /* Allow buttons to grow uniformly */
}

.footer-btn i {
  font-size: 1.25rem;
}

.footer-linkedin {
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  border: 2px solid #aaa;
  justify-content: center;

  min-width: 150px; /* Match min-width and max-width to other buttons */
  max-width: 200px;
  box-sizing: border-box;
  flex-grow: 1;
}

/* Media query for smaller screens */
@media (max-width: 600px) {
  .footer-btn,
  .footer-linkedin {
    min-width: 100%; /* Make buttons full-width on small screens */
    max-width: 100%;
  }
}

hr {
  border-top: 0px solid white; /* Make the hr divider thin */
  margin: 1rem 0;
}
</style>
