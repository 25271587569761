<template>
  <ProductList :title="'Terrastegels'" :products="floorTiles" />
</template>

<script setup>
import ProductList from "@/components/ProductList.vue";

const floorTiles = [
  {
    id: 1,
    name: "Moderne Grijze Terrastegel",
    price: 19.99, //prijs per vierkante mater
    // pack: 2.5, //aantal m2 per verpakking (dus minimale prijs van het product, minder dan dit kan een klant niet kopen)
    image: require("@/assets/examples/tile_example.png"),
  },
  {
    id: 2,
    name: "Elegante Marmeren Terrastegel",
    price: 24.99,
    image: require("@/assets/examples/tile_example.png"),
  },
  {
    id: 3,
    name: "Rustieke Houten Terrastegel",
    price: 17.99,
    image: require("@/assets/examples/tile_example.png"),
  },
  // Voeg meer producten toe zoals nodig
];
</script>
