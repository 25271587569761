<template>
  <ProductList :title="'Vloertegels'" :products="floorTiles" />
</template>

<script setup>
import ProductList from "@/components/ProductList.vue";

const floorTiles = [
  {
    id: 1,
    name: "Moderne Grijze Vloertegel",
    price: 19.99,
    image: require("@/assets/examples/tile_example.png"),
  },
  {
    id: 2,
    name: "Elegante Marmeren Vloertegel",
    price: 24.99,
    image: require("@/assets/examples/tile_example.png"),
  },
  {
    id: 3,
    name: "Rustieke Houten Vloertegel",
    price: 17.99,
    image: require("@/assets/examples/tile_example.png"),
  },
  // Voeg meer producten toe zoals nodig
];
</script>
