<template>
  <div class="container">
    <h1>Algemene voorwaarden PANJSHER B.V.</h1>

    <section>
      <h2>Algemeen</h2>
      <p>
        1. Deze voorwaarden zijn van toepassing op alle aanbiedingen, offertes
        en op alle overeenkomsten alles in de ruimste zin van het woord tussen
        PANJSHER B.V., gevestigd aan de Steenweg op Mol 187 te 2360
        Oud-Turnhout, hierna verder te noemen: PANJSHER, en de KLANT voor zover
        van deze voorwaarden niet door partijen uitdrukkelijk en schriftelijk is
        afgeweken. De KLANT is iedere zakelijke en particuliere wederpartij van
        PANJSHER.
      </p>
    </section>

    <section>
      <h2>Materialen en prijzen</h2>
      <p>
        1. De KLANT geeft de maten en hoeveelheden op voor offertes en
        bestellingen. Hij moet er de juistheid van nagaan en draagt er de
        eindverantwoordelijkheid voor.
      </p>
      <p>
        2. Het aantal tegels geleverd per rekeneenheid (vierkante meter of
        strekkende meter), stemt steeds en uitsluitend overeen met het aantal
        geleverd door de fabrikant, overeenkomstig de door hem gebruikte normen,
        en als dusdanig aangerekend.
      </p>
      <p>
        3. Leveringen gebeuren onder het nadrukkelijk voorbehoud dat tussen
        stalen en geleverde goederen, tint-, maat- en structuurafwijkingen
        kunnen voorkomen. Deze afwijkingen kunnen nooit een basis zijn voor
        eventuele klachten daar ze eigen zijn aan het productieproces van deze
        materialen.
      </p>
      <p>
        4. Info over materialen en prijsaanduidingen op website/webshop en in
        catalogi, folders en offertes zijn steeds onder voorbehoud van eventuele
        drukfouten.
      </p>
      <p>5. De offertes zijn steeds gebaseerd op dagprijzen.</p>
      <p>
        6. De aangeduide prijzen zijn steeds afhaalprijzen, behalve wanneer
        uitdrukkelijk anders overeengekomen.
      </p>
      <p>
        7. Alle materialen worden steeds verkocht per volle verpakking, tenzij
        anders vermeld. De netto te betegelen hoeveelheid in m2 dient
        vermeerderd te worden om tekorten te voorkomen door snijverlies en/of
        speciale legpatronen.
      </p>
      <p>8. De producten worden steeds verkocht zonder plaatsing.</p>
      <p>
        9. Verkochte goederen worden niet teruggenomen, noch geruild. Enkel
        plaatsingsmaterialen, zoals lijm, voegsel, profielen, kunnen
        geretourneerd worden per stuk binnen 30 dagen na levering van de
        bestelling en in ongeopende verpakkingen. Dit op vertoon van factuur.
      </p>
    </section>

    <section>
      <h2>Beschikbaarheid en levering</h2>
      <p>
        1. Levering aan huis gebeurt enkel wanneer de KLANT of een
        vertegenwoordiger van de KLANT persoonlijk aanwezig is op moment van de
        levering. De plaats van levering moet steeds vlot bereikbaar zijn voor
        de chauffeur en zal gebeuren op een met de KLANT afgesproken dag. De
        goederen worden bij Basic Transport aan de rand van het voetpad, op de
        straat afgeleverd. Kiest de KLANT voor Service Transport, dan levert de
        chauffeur de goederen vanop straat op de door de KLANT aangegeven vrije
        plaats (rekening houdend met een maximum reikwijde van 5m, geen levering
        op een verdiep, balkon of schuine oppervlakken). De KLANT is
        verantwoordelijk voor het vrijhouden van een afzetplaats. De KLANT staat
        ook zelf in voor het in huis dragen van de goederen.
      </p>
      <p>
        2. De beschikbaarheidsdatum wordt steeds zonder verbintenis medegedeeld.
        Geen enkele vertraging kan aanleiding geven tot schadevergoeding.
      </p>
      <p>
        3. PANJSHER is in geval van overmacht van iedere verbintenis ontslagen.
        Hieronder dient onder overmacht te worden verstaan: algemene of
        gedeeltelijke werkstakingen, onlusten, ongevallen, machinebreuk,
        schaarste aan vervoermiddelen of grondstoffen, brand, vorst,
        uitzonderlijke regenperiode, stormschade, overstromingen, bij haar of
        bij zijn leveranciers.
      </p>
      <p>
        4. De goederen moeten binnen de vastgestelde termijn afgehaald of
        geleverd worden. Bij gebreke, mag PANJSHER, hetzij de overeenkomst
        verbreken, hetzij er de uitvoering van eisen, zonder verhaal voor schade
        of vergoeding.
      </p>
    </section>

    <section>
      <h2>Betaling</h2>
      <p>
        1. Iedere bestelling of overeenkomst is slechts bindend na betaling van
        een voorschot. De waarde van het voorschot bedraagt 21% van het totale
        bestelbedrag tenzij anders overeengekomen met PANJSHER. Wanneer de KLANT
        een lever- of afhaaldatum wenst te bevestigen, dient de KLANT eerst het
        saldo te betalen.
      </p>
      <p>
        2. Elke bestelling is bindend na betaling van het voorschot. Wanneer de
        bestelling wordt geannuleerd na betaling van het voorschot, zal het
        voorschot volledig worden ingehouden.
      </p>
      <p>
        3. PANJSHER blijft eigenaar van de geleverde goederen zolang ze niet
        werden betaald, ook wanneer de KLANT ze aan een derde heeft geleverd of
        heeft gegeven.
      </p>
      <p>
        4. Bij niet-naleving op de vervaldag worden de factuurbedragen van
        rechtswege vermeerderd met een interest van 1,5% per maand, of fractie
        van de maand, en dit zonder voorafgaandelijke ingebrekestelling.
      </p>
      <p>
        5. Elke wijziging in de toestand van de KLANT, onvermogen, ontbinding of
        wijziging van vennootschap, faillissement, surseance van betaling of
        achterstallige betaling, geeft PANJSHER het recht zekerheden te eisen of
        een gedeelte van of de gehele koop op te schorten of de overeenkomst te
        ontbinden. Indien zich een situatie als genoemd in lid 8 voordoet, dan
        stelt de KLANT PANJSHER daarvan onverwijld, maar uiterlijk binnen 24
        uur, schriftelijk in kennis.
      </p>
      <p>
        6. In die gevallen wordt de waarde van de reeds geleverde goederen van
        de betwiste bestellingen onmiddellijk opeisbaar zonder dat hierdoor
        afstand gedaan wordt van de schadevergoedingen, en intresten, die
        PANJSHER van rechtswege toegekend worden.
      </p>
      <p>
        7. Wanneer een rekening niet betaald wordt binnen de 15 dagen na de
        vervaldag, wordt het bedrag ervan van rechtswege en zonder
        ingebrekestelling, verhoogd met een vergoeding van 10%, met een minimum
        van € 100,= administratiekosten.
      </p>
    </section>

    <section>
      <h2>Schade aan materialen en ruilen</h2>
      <p>
        1. Klachten betreffende beschadigde materialen of tekorten, moeten
        binnen de 4 werkdagen na afhaling of na levering gemeld worden. Bij meer
        dan 2% beschadigde of gebroken tegels dient contact met de klantendienst
        opgenomen te worden om een omruiling in in te plannen. Gebroken tegels
        kunnen enkel omgeruild worden door dezelfde referentie. Omruilen voor
        een andere referentie of een terugbetaling vragen zijn niet mogelijk.
      </p>
      <p>
        2. De waarborg op de geleverde goederen kan slechts ingeroepen worden
        gedurende een periode van maximum 18 maanden na factuurdatum.
      </p>
      <p>
        3. Alle materialen moeten gecontroleerd worden voor plaatsing. Voor
        zichtbare gebreken zal na plaatsing geen enkele klacht in aanmerking
        genomen worden. Hierbij kan gedacht worden aan haarscheurtjes, kromme of
        bolle tegels, foutjes in oppervlak, tintverschillen, et cetera. De KLANT
        moet steeds, vooraleer plaatsing, controleren of alle geleverde dozen
        hetzelfde lotnummer hebben, dit om kleurverschillen te voorkomen.
      </p>
      <p>
        5. Materialen besteld via onze website kunnen binnen 14 dagen
        geretourneerd worden. Hiervoor neemt de KLANT contact op met de
        klantendienst. De kost voor deze retourname is steeds voor de KLANT. Als
        er tegels geretourneerd worden, dan dient de KLANT de volledige
        bestelling terug te sturen.
      </p>
      <p>
        6. De KLANT verbindt er zich toe de aangekochte producten uitsluitend
        overeenkomstig de doeleinden en aanduidingen hem verstrekt in de
        toonzaal te gebruiken. Tegels die buiten hun normale bestemming gebruikt
        worden, of die niet verwerkt of geplaatst worden volgens de ter zake
        geldende normen en regels van de kunst, zijn door dit feit zelf
        uitgesloten van elke waarborg.
      </p>
      <p>
        7. PANJSHER mag het terugzenden van gebrekkige goederen eisen. Zij
        behoudt zich het recht om deze te vervangen. De tegemoetkoming aan de
        KLANT kan nooit verder gaan dan tot eenvoudige vervanging van de als
        gebrekkig erkende goederen.
      </p>
      <p>
        8. Iedere klacht voor onzichtbaar gebrek moet ingediend worden binnen 3
        maanden na levering. De klacht wordt niet gehonoreerd wanneer het
        product beantwoordt aan de ter zake geldende normen.
      </p>
      <p>
        9. De door de fabrikant opgegeven graad van slijtvastheid geldt enkel
        ten titel van inlichting. Bij het aanwenden van vloeren, is het inbouwen
        van een vloermat aan elke buiteningang verplicht. Zand, stof en haren
        beperken in aanzienlijke mate de levensduur van de toplaag. Bij
        vaststelling van onvoldoende onderhoud is iedere verantwoordelijkheid
        van PANJSHER uitgesloten. De glanzende toplaag van sommige materialen
        kan haar initieel aspect verliezen bij gebruik. Dit verschijnsel wordt
        als normaal beschouwd en kan geen enkele klacht rechtvaardigen.
      </p>
      <p>
        10. De vorstvrijheid van geglazuurde keramiek wordt nooit gegarandeerd.
      </p>
      <p>
        11. Natuursteen verandert na verloop van tijd van structuur, kleur en
        oppervlak en vergt een speciaal onderhoud.
      </p>
    </section>

    <section>
      <h2>Aansprakelijkheid</h2>
      <p>
        1. PANJSHER is, met uitzondering van opzet of grove schuld, niet
        aansprakelijk voor schade, van welke aard ook, ontstaan doordat PANJSHER
        is uitgegaan van door of namens de KLANT verstrekte onjuiste en/of
        onvolledige gegevens.
      </p>
      <p>
        2. Indien PANJSHER aansprakelijk mocht zijn voor enigerlei schade, dan
        is de aansprakelijkheid van PANJSHER beperkt tot maximaal de
        factuurwaarde van de order, althans tot dat gedeelte van de order waarop
        de aansprakelijkheid betrekking heeft.
      </p>
      <p>
        3. Indien PANJSHER voor de ontstane schade is verzekerd, is de
        aansprakelijkheid van PANJSHER is in ieder geval steeds beperkt tot het
        bedrag van de uitkering van haar verzekeraar in voorkomend geval, mocht
        het uitgekeerde bedrag hoger zijn dan maximaal het bedrag van de
        factuurwaarde van de order, althans tot dat gedeelte van de order waarop
        de aansprakelijkheid betrekking heeft.
      </p>
      <p>
        4. PANJSHER is steeds uitsluitend aansprakelijk voor directe schade.
        Onder directe schade wordt uitsluitend verstaan de redelijke kosten ter
        vaststelling van de oorzaak en de omvang van de schade, voor zover de
        vaststelling betrekking heeft op schade in de zin van deze voorwaarden,
        de eventuele redelijke kosten gemaakt om de gebrekkige prestatie van
        PANJSHER aan de overeenkomst te laten beantwoorden, voor zoveel deze aan
        PANJSHER toegerekend kunnen worden en redelijke kosten, gemaakt ter
        voorkoming of beperking van schade, voor zover de KLANT aantoont dat
        deze kosten hebben geleid tot beperking van directe schade als bedoeld
        in deze algemene voorwaarden. PANJSHER is dus nooit aansprakelijk voor
        indirecte, afgeleide schade, daaronder begrepen gevolgschade, gederfde
        winst, gemiste besparingen en schade door bedrijfsstagnatie.
      </p>
    </section>

    <section>
      <h2>Garantie</h2>
      <p>
        1. Op de door PANJSHER geleverde goederen gelden de door haar
        leveranciers vastgestelde garantiebepalingen, tenzij anders wordt
        overeengekomen.
      </p>
      <p>
        2. Voor zover niet anders overeengekomen, wordt op verrichte
        werkzaamheden geen garantie gegeven.
      </p>
      <p>3. Alle garantieverplichtingen vervallen indien:</p>
      <ul>
        <li>
          er nabewerkingen, wijzigingen of reparaties zonder onze voorafgaande
          toestemming zijn uitgevoerd door de opdrachtgever, dan wel door haar
          ingeschakelde derden;
        </li>
        <li>het geleverde niet goed is onderhouden;</li>
        <li>
          het geleverde onoordeelkundig of niet conform de bestemming is
          gebruikt. alles ter beoordeling van PANJSHER.
        </li>
      </ul>
    </section>

    <section>
      <h2>Annulering, opschorting en beëindiging</h2>
      <p>
        1. PANJSHER is bevoegd de nakoming van de verplichtingen op te schorten
        of de overeenkomst te ontbinden, indien de opdrachtgever de
        verplichtingen uit de overeenkomst niet, niet volledig of niet tijdig
        nakomt, na het sluiten van de overeenkomst PANJSHER ter kennis gekomen
        omstandigheden goede grond geven te vrezen dat de opdrachtgever de
        verplichtingen niet zal nakomen, indien de opdrachtgever bij het sluiten
        van de overeenkomst verzocht is om zekerheid te stellen voor de
        voldoening van zijn verplichtingen uit de overeenkomst en deze zekerheid
        uitblijft of onvoldoende is of indien door de vertraging aan de zijde
        van de opdrachtgever niet langer van PANJSHER kan worden gevergd dat hij
        de overeenkomst tegen de oorspronkelijk overeengekomen condities zal
        nakomen.
      </p>
      <p>
        2. Voorts is PANJSHER bevoegd de overeenkomst te ontbinden indien zich
        omstandigheden voordoen welke van dien aard zijn dat nakoming van de
        overeenkomst onmogelijk is of indien er zich anderszins omstandigheden
        voordoen die van dien aard zijn dat ongewijzigde instandhouding van de
        overeenkomst in redelijkheid niet van PANJSHER kan worden gevergd.
      </p>
    </section>

    <section>
      <h2>Geschillen en toepasselijk recht</h2>
      <p>
        1. Op alle onder deze voorwaarden gesloten overeenkomsten en op de
        overeenkomsten die daaruit voortvloeien is uitsluitend Nederlands recht
        van toepassing en de Nederlandse rechter bevoegd.
      </p>
    </section>
  </div>
</template>

<script>
export default {
  name: "TermsAndConditionsPage",
};
</script>

<style scoped>
.terms-and-conditions {
  background-color: #f9f9f9;
  padding: 40px 20px;
  font-family: "Arial", sans-serif;
  line-height: 1.7;
  color: #333;
}

.container {
  max-width: 900px;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

h1 {
  font-size: 2.5rem;
  color: #2c3e50;
  text-align: left;
  margin-bottom: 20px;
}

h2 {
  font-size: 1.8rem;
  color: #34495e;
  margin-top: 30px;
  margin-bottom: 15px;
  border-left: 4px solid #3498db;
  padding-left: 10px;
}

p {
  font-size: 1.1rem;
  text-align: left;
  margin-bottom: 10px;
}

ul {
  text-align: left;
}

section {
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .container {
    padding: 15px;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1rem;
  }
}
</style>
